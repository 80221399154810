<template>
    <zw-sidebar @shown="shown" title="Vorgänge">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <b-table responsive
                 striped
                 hover
                 :items="getProcesses()"
                 :fields="fields"
                 no-provider-sorting
                 head-row-variant="info"
                 foot-row-variant="info"
                 sticky-header="100%"
        >

            <template #cell(project_production.id)="row">
                <a @click.prevent="$root.$children[0].openModal('kva-modal', row.item.id)" href="#">{{
                        zeroPad(row.item.project_production.id,
                            4)
                    }}</a>
            </template>
            <template #cell(contact_person.name)="row">
                {{ row.item.vorname }} {{ row.item.name }}
            </template>
        </b-table>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    data() {
        return {
            loading: true,
            payload: {},
            fields: [
                {key: 'project_production.id', 'label': 'Proj. Nr'},
                {key: 'project_production.name', 'label': 'Projekt'},
                {key: 'contact_person.name', 'label': 'Ansprechpartner'},
                {key: 'waehrung_short', 'label': 'Betrag'},
            ],
        }
    },
    methods: {
        ...mapGetters('Sales', ['getProcesses']),
        shown() {
            this.$store.dispatch('Sales/fetchProcesses', this.payload)
                .then(() => {
                    this.loading = false
                }, () => {
                    this.loading = false
                })
        },
        zeroPad(num, places) {
            let zero = places - num.toString().length + 1;
            return Array(+(zero > 0 && zero)).join("0") + num;
        }
    }
}
</script>